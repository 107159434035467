import React, { useState } from "react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM, { createRoot } from "react-dom/client";
import BrandProduct from "./components/BrandProduct";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";
import Login from "./components/Login";
import Register from "./components/Register";
import Brands from "./components/Brands";
import "flowbite";
import Favourites from "./components/Favourites";
import ChargingDetails from "./components/Product/ChargingDetails";
import ForgotPassword from "./components/ForgotPassword";
import Error from "./components/Error";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement:<Error/>,
    children: [
      {
        path: "/",
        element: <Body />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "/product/:model",
        element: <BrandProduct />,
      },
    
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/favourites",
        element: <Favourites />,
      },
      {
        path: "/brands",
        element: <Brands />,
      },
      {
        path: "/chargingdetails",
        element:<ChargingDetails/>,
      },
    ],
  },
]);

root.render(<RouterProvider router={router} />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
