import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import {HomeData} from '../contants/data';
import { useState, useEffect } from "react";
import Shimmer from "./Shimmer";

const Body = () => {
const [cardData, setCarsData]= useState(HomeData);
const imageSrc = 'https://res.cloudinary.com/deej1begz/image/upload/v1687496856/productImages/n35ho281tdf3z6fifswl.jpg'
// async function getData() {
//   const fetchData = await fetch('https://tidyboy.de/electrikAPI.json',{
  
//   });
//   const ndata = await fetchData.json();
//   setCarsData(ndata.brandsData);
// }


// useEffect(()=>{
//   getData();
//   console.log(cardData);
// },[])

if (!cardData) return null;

  return cardData.length === 0 ? (<Shimmer/>) : (
    <>
        <Header/>
      <div className="container mx-2 w-full p-0 max-w-fit homeblocks">
        {
          cardData.map((item)=>{
            return(
              <Link to={"/product/" + item.id} key={item.id}>
              <Home src={item.brandImage} brandLogo={item.brandLogo} key={item.id} />
              </Link>
            )
          })
        }
      </div>
      <Footer/>
    </>
  );
};

export default Body;
