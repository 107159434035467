import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Audi2 from "../assets/images/home-page/audi/Audi02.jpeg";
import BMW from "../assets/images/home-page/bmw/new/BMW02C.jpeg";
import MERCEDES from "../assets/images/home-page/mercedes/merc02d.png";
import { Carousel } from "flowbite-react";
import {PRODUCT_DATA} from '../contants/data';
import { Link, useNavigate } from "react-router-dom";

const Favourites = () => {
const url = 'https://api_fss.avp-applination.com/api/getfavoritebyuserid';
  async function fetchHandler (){
    try {
      const response = await fetch(url,{
        method: 'GET',
        headers: {
          "Content-Type":  'application/json',
          "Authorization": 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          "token": `${localStorage.getItem('token')}`
        }
      });
const dataResponse = await response.json();

console.log(dataResponse.length);
if(dataResponse.status==true){
  setFavorites(dataResponse.data);
}
    }
      catch (error){
        console.log(error)
      }
  }
  const [favorites, setFavorites] = useState([]);
  
  //const filteredData = PRODUCT_DATA?.data.map(({data, ...rest}) => ({...rest, data: data.filter(({modelId}) => favorites.some(({modelId: fModelId, favorite_status}) => modelId === fModelId && favorite_status === 1))}));

  useEffect(() => {
    fetchHandler();
    
  }, []);
  
  if(!favorites) return null;
console.log(favorites);

const filteredData = [];

PRODUCT_DATA.data.forEach((brand) => {
  brand.data.forEach((model) => {
    if (favorites.some((fav) => fav.modelId == model.modelId)) {
      filteredData.push(model);
      
    }
  });
});

console.log(filteredData);



  return (
    <>
    
      <>
      <Header/>
        <div className="container px-10 pb-10">
        <Link to ="/"><div className="mt-5 mb-5"> <span aria-hidden="true">&larr;</span> Back</div></Link>
          <h3 className="text-3xl font-semibold mb-5">Favourite EV's</h3>
          <div className="flex justify-center flex-wrap">
            {localStorage.getItem('isLoggedIn') == false ? 'Please login': ''}
{filteredData.length===0 ? (`${'No data found'}`): ''}
{filteredData.map((item)=>{
return <>
<div className="block mr-7 mb-10 w-[350px] bg-[#f6f6f6] border border-[#eee] dark:bg-neutral-700">
            <Carousel indicators={false} className="favourite_slider">
            {item?.productImages.map((e) => (
              <img className="object-cover h-[100%]" src={e.url} alt="..." />
            ))}
          </Carousel>
              <div className="">
                <h5 className="mb-2 p-3 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                 {item?.model}
                </h5>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Price</div>
                  <div>{item?.startPrice} €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Leasing Pries ab</div>
                  <div>{item?.leasingPrice} €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>0–100 km/h</div>
                  <div>{item?.kmh} sec</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Reichweite (WLTP)</div>
                  <div>{item?.rangeWLTP}km</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Battery size</div>
                  <div>{item?.batteryCapacity} kwh</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Lieferzeitraum</div>
                  <div>1 Monat</div>
                </div>
              
            
                <div className="px-2 py-4">
                <button
                  type="button"
                  className="bg-black text-white rounded-full px-8 py-2">
                  Book a test drive
                </button>
                </div>
              </div>
            </div>
</>
})}
          <div>
  
    </div>
    </div>
    

          
          {/* <div className="flex justify-center">
            <div className="block mr-7 w-[350px] bg-[#f6f6f6] border border-[#eee] dark:bg-neutral-700">
            <Carousel indicators={false} className="favourite_slider">
            {productImages.map((e) => (
              <img className="object-cover h-[100%]" src={e.url} alt="..." />
            ))}
          </Carousel>
              <div className="">
                <h5 className="mb-2 p-3 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                  Audi e-tron GT quattro
                </h5>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Price</div>
                  <div>48,000 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Leasing Pries ab</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>0–100 km/h</div>
                  <div>5.1sec</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Reichweite (WLTP)</div>
                  <div>501km</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Battery size</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Lieferzeitraum</div>
                  <div>1 Monat</div>
                </div>
              
            
                <div className="px-2 py-4">
                <button
                  type="button"
                  className="bg-black text-white rounded-full px-8 py-2">
                  Book a test drive
                </button>
                </div>
              </div>
            </div>
            <div className="block mr-7 w-[350px] bg-[#f6f6f6] border border-[#eee] dark:bg-neutral-700">
            <Carousel indicators={false} className="favourite_slider">
            {productImages.map((e) => (
              <img className="object-cover h-[100%]" src={e.url} alt="..." />
            ))}
          </Carousel>
              <div className="">
                <h5 className="mb-2 p-3 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                  Audi e-tron GT quattro
                </h5>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Price</div>
                  <div>48,000 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Leasing Pries ab</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>0–100 km/h</div>
                  <div>5.1sec</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Reichweite (WLTP)</div>
                  <div>501km</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Battery size</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Lieferzeitraum</div>
                  <div>1 Monat</div>
                </div>
              
           
                <div className="px-2 py-4">
                <button
                  type="button"
                  className="bg-black text-white rounded-full px-8 py-2">
                  Book a test drive
                </button>
                </div>
              </div>
            </div>
            <div className="block mr-7 w-[350px] bg-[#f6f6f6] border border-[#eee] dark:bg-neutral-700">
            <Carousel indicators={false} className="favourite_slider">
            {productImages.map((e) => (
              <img className="object-cover h-[100%]" src={e.url} alt="..." />
            ))}
          </Carousel>
              <div className="">
                <h5 className="mb-2 p-3 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                  Audi e-tron GT quattro
                </h5>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Price</div>
                  <div>48,000 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Leasing Pries ab</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>0–100 km/h</div>
                  <div>5.1sec</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Reichweite (WLTP)</div>
                  <div>501km</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Battery size</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Lieferzeitraum</div>
                  <div>1 Monat</div>
                </div>
   
                <div className="px-2 py-4">
                <button
                  type="button"
                  className="bg-black text-white rounded-full px-8 py-2">
                  Book a test drive
                </button>
                </div>
              </div>
            </div>
            <div className="block mr-7 w-[350px] bg-[#f6f6f6] border border-[#eee] dark:bg-neutral-700">
            <Carousel indicators={false} className="favourite_slider">
            {productImages.map((e) => (
              <img className="object-cover h-[100%]" src={e.url} alt="..." />
            ))}
          </Carousel>
              <div className="">
                <h5 className="mb-2 p-3 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                  Audi e-tron GT quattro
                </h5>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Price</div>
                  <div>48,000 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Leasing Pries ab</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>0–100 km/h</div>
                  <div>5.1sec</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Reichweite (WLTP)</div>
                  <div>501km</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Battery size</div>
                  <div>860,00 €</div>
                </div>
                <div className="flex border-b border-[#dcdcdc] justify-between px-3 pb-1.5">
                  <div>Lieferzeitraum</div>
                  <div>1 Monat</div>
                </div>
              
            
                <div className="px-2 py-4">
                <button
                  type="button"
                  className="bg-black text-white rounded-full px-8 py-2">
                  Book a test drive
                </button>
                </div>
              </div>
            </div>
            
          </div> */}
        </div>
        <Footer/>
      </>
     
    </>
  );
};

export default Favourites;
