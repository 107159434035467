import { Fragment, useRef, useState, useContext, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import Logo from "../assets/images/logo-elec.jpeg";
import "./Header.css";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  UserIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  ExclamationTriangleIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import Brands from "./Brands";
import Login from "./Login";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loginForm, setLoginForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const logoutHandler = ()=>{
    localStorage.setItem('isLoggedIn', false)
    localStorage.removeItem('userid')
    localStorage.removeItem('firstname')
    localStorage.removeItem('lastname')
    localStorage.removeItem('token')
    localStorage.removeItem('favouritesCounts')
    setIsLoggedIn(false);
    navigate("/login"); 

  }
  function checkLoggedIn(){
if (localStorage.getItem('isLoggedIn') === 'true'){
  return setIsLoggedIn(true)
}
  }
useEffect(()=>{
checkLoggedIn();
},[])
  return (
    <>
   
      <header className="bg-white main-header">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-1 lg:px-4"
          aria-label="Global"
        >
          <div className="flex lg:flex-1 py-2 header-logo">
            <Link to="/">
                <span className="sr-only">Your Company</span>
                <img className="h-20 w-auto" src={Logo} alt="" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-9 w-9" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            {/* <a
              href="#"
              className="text-md font-semibold leading-6 text-gray-900"
            >
              Audi
            </a>
            <a
              href="#"
              className="text-md font-semibold leading-6 text-gray-900"
            >
              Mercedes
            </a>
            <a
              href="#"
              className="text-md font-semibold leading-6 text-gray-900"
            >
              BMW
            </a> */}
<p className="italic pt-2 text-lg text-right float-right">Compare all EV's under one roof</p>

            <button
            onClick={() => setOpen(true)}
              className="bg-white border border-black text-black w-[280px] px-3 py-2 float-right ml-[100px]"
            >
              <span className="float-left">Search by Brands</span> 
              <span className="pl-[6px] pt-[4px] float-right"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>
</span>
            </button>
          </Popover.Group>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <div className="relative mt-2 mr-5">
              <Link to="/favourites">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    className=""
                    fill="#000"
                    strokeWidth="0.4"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M19.66 3.99c-2.64-1.8-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.91-7.66-1.1-1.4.96-2.28 2.58-2.34 4.29-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1c5.25-4.76 8.68-7.87 8.55-11.75-.06-1.7-.94-3.32-2.34-4.28zM12.1 18.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"></path>
                  </svg>
                  <p className="style-favourite"></p>
                </a>
              </Link>
            </div>
         

{isLoggedIn && <button onClick = {logoutHandler}
              className="bg-black text-white rounded-full px-4 py-4"
            >
              Logout
            </button>}
                
            {!isLoggedIn &&
             <Link to='/login' className="mt-2">
              <div>Login / Register <UserIcon className="h-6 w-6 float-right" aria-hidden="true" /></div>
            </Link>
}
              
          
                
      
             
                
           

          
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="w-[70%] fixed inset-y-0 right-0 z-10 overflow-y-auto bg-[#fafafa] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              {/* <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a> */}
              
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Search by brands
                  </a>
                  
                </div>
                <div className="py-6">
                  <a className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Login/Register
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full max-w-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[80%]">
                    <div className="bg-white w-full pt-5 sm:p-6 sm:pb-4">
                      <div className="flex w-full sm:flex sm:items-start">
                        <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            <div className="flex justify-between">
                              <div className="text-xl">
                                Search by manufacturer
                              </div>
                              <div className="">
                                <a
                                  onClick={() => setOpen(false)}
                                  className="cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-8 h-8"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </Dialog.Title>

                          <div>
                            <Brands />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        
      </header>
     
    </>
  );
};

export default Header;
