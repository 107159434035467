const Footer = () => {
  return (
    <>
      <footer aria-label="Site Footer" className="bg-black">
        <div className="max-w-screen-xl px-4 py-10 mx-auto space-y-8 sm:px-6 lg:space-y-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
              <div>
                <p className="font-medium text-white">Brands</p>

                <nav
                  aria-label="Footer Navigation - Services"
                  className="mt-6 text-[#8e8e8e]"
                >
                  <ul className="space-y-4 text-sm">
                    <li>
                      <a className="transition hover:opacity-75">Audi</a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        BMW
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Mercedes
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Polestar
                      </a>
                    </li>

                    <li>
                      <a className="transition hover:opacity-75">Hyundai</a>
                    </li>

                    <li>
                      <a className="transition hover:opacity-75">VW</a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div>
                <p className="font-medium text-white">Company</p>

                <nav
                  aria-label="Footer Navigation - Company"
                  className="mt-6 text-[#8e8e8e]"
                >
                  <ul className="space-y-4 text-sm">
                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        About
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Brand Collabration
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* <div>
                <p className="font-medium text-white">Helpful Links</p>

                <nav
                  aria-label="Footer Navigation - Company"
                  className="mt-6 text-[#8e8e8e]"
                >
                  <ul className="space-y-4 text-sm">
                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Blog
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        FAQs
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Newsletter
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}

              <div>
                <p className="font-medium text-white">Legal</p>

                <nav
                  aria-label="Footer Navigation - Legal"
                  className="mt-6 text-[#8e8e8e]"
                >
                  <ul className="space-y-4 text-sm">
                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Accessibility
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Terms and Conditions
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div>
                <p className="font-medium text-white">Visit us on</p>

                <nav
                  aria-label="Footer Navigation - Legal"
                  className="mt-6 text-[#8e8e8e]"
                >
                  <ul className="space-y-4 text-sm">
                    <li>
                      <a className="transition hover:opacity-75">Facebook</a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Twitter
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        Instagram
                      </a>
                    </li>

                    <li>
                      <a href="#" className="transition hover:opacity-75">
                        YouTube
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <p className="text-xs text-white">
            &copy; 2023. elektroautoshowroom.com All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
