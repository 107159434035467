import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Outlet />
      {/* <ScrollToTop/> */}
    </div>
  );
}

export default App;
