import React, { Fragment,useRef,useState, useEffect } from "react";
import { Dialog, Disclosure, Popover, Transition, Listbox } from "@headlessui/react";
import { Collapse, Text, Grid } from "@nextui-org/react";
import "./BrandProduct.css";
import { Carousel } from "flowbite-react";
import logo from "../assets/images/EV-logo.png";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {PRODUCT_DATA} from '../contants/data';
import chargeIcon from '../assets/images/power-plug.png';
import BookTestDriveForm from "./Product/BookTestDriveForm";
import appleCarPlayLogo from '../assets/images/apple_carplay.png';
import amazonAlexaLogo from '../assets/images/amazon_alexa.png';
import androidAutoLogo from '../assets/images/android-auto.png';
import blueLinkLogo from '../assets/images/bluelink_logo.webp';
import VivadiLogo from '../assets/images/vivaldi_browser_logo.svg';
import cariadLogo from '../assets/images/cariad_logo.jpeg';
import Shimmer from "./Shimmer";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  UserIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  ExclamationTriangleIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
const UnFavouriteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="#B180D1"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
    />
  </svg>
);

const favouriteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="red"
    className="w-6 h-6"
  >
    <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
  </svg>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BrandProduct = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
const { model } = useParams();
const [favourite, setFavourite] = useState([]);
const [productData, setProductData] = useState([]);
const [chargingModel, setChargingModel] = useState(false);
const [imageData, setImageData] = useState([]);
const cancelButtonRef = useRef(null);

const filterData = () => {
  const data =  PRODUCT_DATA.data.filter((item) => item.model === model);
  return setProductData(data);
}

const url2 = 'http://backendnode.elektroautoshowroom.com/api/getfavoritebyuserid';
  async function fetchHandler (){
    try {
      const response = await fetch(url2,{
        method: 'GET',
        headers: {
          "Content-Type":  'application/json',
          "Authorization": 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          "token": `${localStorage.getItem('token')}`
        }
      });
const dataResponse = await response.json();

console.log(dataResponse);
if(dataResponse.status==true){
  setNewFilterData(dataResponse.data)
}
    }
      catch (error){
        console.log(error)
      }
  }
const url = 'http://backendnode.elektroautoshowroom.com/api/favoritemodelbyuserid';
const favouriteHandler = async () => {
  try {
    const response = await fetch(url,{
      method: 'POST',
      body: JSON.stringify({
        userid: localStorage.getItem('userid'),
        modelId: selectedModelData?.modelId,
        status: 1
      }),
      headers: {
        "Content-Type":  'application/json',
        "Authorization": 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
        "token": `${localStorage.getItem('token')}`
      }
    });
const dataResponse = await response.json();
console.log(dataResponse);
setIsFavourite(0)

  }
    catch (error){
      console.log(error)
    }
}

const [selected, setSelected] = useState(filterData);
const [selectedModel, setSelectedModel] = useState();
const [bookTestDrive, setBookTestDrive] = useState(false);
const [newFilterData, setNewFilterData] = useState([]);
const [isFavourite, setIsFavourite] = useState([]);
const [isLeftIcon, setIsLeftIcon] = useState([]);
const [modelID, setModelID] = useState();
  const handleChange = (event) => {
    setSelectedModel(event.target.value);
  };

const selectedModelData = productData[0]?.data.find((model) => model.modelId === selectedModel) || productData[0]?.submodels?.find((model) => model.modelId === selectedModel) ;

function datanewfilter (){
  const filteredData = newFilterData.filter(data => data.modelId === 100);
  return setIsFavourite(filteredData[0]?.favorite_status)
}
useEffect(()=>{
  filterData();
  fetchHandler();
  datanewfilter();
  setImageData(productData[0].productImages);
  setSelectedModel(productData[0]?.data[0]?.modelId);
  // console.log("sub", productData[0]?.submodels.length)
  // console.log(productData[0].data[0].modelId);
  // console.log(productData[0].data);
  // console.log(PRODUCT_DATA.data[0].data[0].modelId);
  // console.log(PRODUCT_DATA.data[0].data)

  //console.log('selectedid', productData[0]?.data[0]?.modelId)
  console.log('filterdata', newFilterData);
  
},[])
console.log('newdata', isFavourite)
useEffect(()=>{
  setModelID(selectedModelData?.modelId);
  console.log('selectedid', selectedModelData?.modelId);
})

 const carColorSelect =()=>{}
  return productData.length === 0 ? (<Shimmer/>) : (    
    <>
      <div className="container pl-3 pr-3 w-full max-w-full">
        <div className="flex brand-main">
        <div className="w-[70%] max-w-[70%] image-slider">
          <div className="header-mobile flex align-center justify-between">
          <div>   <Link to="/"><span className="back-btn" aria-hidden="true">&larr;</span> </Link></div>
          <div className='pt-[5px]'>
                <img src={productData[0]?.brandLogo} className="max-h-[73px] ml-10" alt="" />
              </div>
         
             
            <div className="ml-2 mt-2">
            <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-9 w-9" aria-hidden="true" />
            </button>
            <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="w-[70%] fixed inset-y-0 right-0 z-10 overflow-y-auto bg-[#fafafa] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              {/* <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a> */}
              
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Search by brands
                  </a>
                  
                </div>
                <div className="py-6">
                  <a className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Login/Register
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
          </div>
            </div>
             
          </div>
          <div className="flex justify-center header-mobile">
          <div className="pt-[25px]">
              <select value={selectedModel} onChange={handleChange}>
        {productData[0]?.data.map((model) => (
          <option key={model.modelId} value={model.modelId}>
            {model.model}
          </option>
        ))}
      </select>
      </div>
{/* (productData[0].submodels.length) */}


      <div className="pt-[25px] pl-5">
     
      {(productData[0]?.submodels)?  (<select className="ml-2" value={selectedModel} onChange={handleChange}>
        {productData[0]?.submodels?.map((model) => (
          <option key={model.modelId} value={model.modelId}>
            {model.model}
          </option>
        ))}
      </select> ): ''} 
      </div>
     
</div>
            <div className="flex justify-between items-center mt-0 header-desktop">
              <div>
                <Link to="/">
                  <button className="rounded-full w-28 py-3 ml-4 bg-black text-white">
                    <span aria-hidden="true">&larr;</span> Back
                  </button>
                </Link>
              </div>
              <div className=''>
                <img src={productData[0]?.brandLogo} className="max-h-[73px] ml-10" alt="" />
              </div>
              <div>
              {/* <select onChange={onProductChange} className="form-select">
                {productData.map((person)=>(
                    <option key={person?.modelId}>{person?.model}</option>
                ))}
      </select> */}
      <select value={selectedModel} onChange={handleChange}>
        {productData[0]?.data.map((model) => (
          <option key={model.modelId} value={model.modelId}>
            {model.model}
          </option>
        ))}
      </select>
      {(productData[0]?.submodels)?  (<select className="ml-2" value={selectedModel} onChange={handleChange}>
        {productData[0]?.submodels?.map((model) => (
          <option key={model.modelId} value={model.modelId}>
            {model.model}
          </option>
        ))}
      </select> ): ''}
     

              </div>
              <div className="ml-6">
                <button onClick={() => setChargingModel(true)} className="p-3">
                  <img src={chargeIcon} width='25px'/>
                </button>
            
              </div>
            </div>
            <div className="mt-6 relative">
            <div className="ml-2 absolute favourite-main">
              <button onClick={favouriteHandler} className="p-2 mt-[15px] favourite-btn">
                {isFavourite===1 ? favouriteIcon:UnFavouriteIcon}
              </button>
            </div>
         <Carousel indicators={false} leftControl={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
} rightControl={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>
} className="slider_img">
         {selectedModelData?.productImages.map((e) => (
           <img className="object-contain h-[96%]" src={e.url} alt="..." />
         ))}
       </Carousel>
            </div>
          </div>
<div className="features-brand flex mt-[20px]">
<div className="w-full text-center">
<p className="italic text-sm">Startpreis ab</p>
              <p className="text-xl font-semibold">{`${selectedModelData?.startPrice} €`} </p>
</div>
<div className="w-full text-center">
              <p className="italic text-sm">Leasing Pries ab</p>
              <p className="text-xl font-semibold">{`${selectedModelData?.leasingPrice} €`}</p>
            </div> 
            <div className="w-full text-center">
<p className="italic">umweltbonus</p>
              <p className="text-xl font-semibold">{selectedModelData?.environmentalBonus==''?'-':`${selectedModelData?.environmentalBonus} €`}</p>
</div>
</div>

<div className="features-brand flex mt-[20px]">

<div className="w-full text-center">
<p className="text-lg font-semibold">
                  1 Monat
                  </p>
                  <p className="italic text-sm pt-1">Lieferzeitraum</p>
            </div> 
            <div className="w-full text-center">
<p className="text-lg font-semibold">
                    {selectedModelData?.batteryCapacity}<small className="text-xs">kwh</small>
                  </p>
                  <p className="italic text-sm pt-1">Battery size</p>
</div>
<div className="w-full text-center">
<p className="text-lg font-semibold">
                {selectedModelData?.kmh}<small className="text-xs"> sec</small>
                </p>
                <p className="italic text-sm pt-1">0–100 km/h</p>
</div>
</div>

<div className="features-brand flex mt-[20px]">
<div className="w-full text-center">
<p className="text-lg font-semibold">
                {selectedModelData?.rangeWLTP}<small className="text-xs">km</small>
                </p>
                <p className="italic text-sm pt-1">Reichweite (WLTP)</p>
            </div> 


<div className="w-full text-center">
<p className="text-lg font-semibold">
                  {selectedModelData?.maxSpeed}<small className="text-xs"> km/h</small>
                </p>
                <p className="italic text-sm pt-1">Max. Speed</p>
            </div>  
            <div className="w-full text-center">
            <button onClick={() => setChargingModel(true)} className="w-[80%] mt-1 py-2 bg-[#61e001] text-white capitalize rounded-lg text-sm">
           
              <i>Charging Details</i><br/>
              <i className="text-xs">(Click here)</i>
            </button>
            </div> 
</div>



          <div className="w-[30%] max-w-[30%] image-slider">
          <Link to='/login' className="mt-3 mb-2 float-right mr-2 header-desktop">
              <div>Login / Register <UserIcon className="h-6 w-6 float-right" aria-hidden="true" /></div>
            </Link>
            <div className="carfeatures">
            <Grid.Container gap={2}>
            <div className="float-right w-full header-desktop">
              
            </div>
              <Grid>
              
                <Collapse.Group splitted className="product-info">
                  <Collapse
                    title="On Board Softwares"
                    className="collapse-bg"
                    showArrow={true}
                  >
                    <Text>
                    <div className="flex flex-row justify-around pt-5">
                     {selectedModelData?.onBoardComputer.map((data)=>{
return <>


{data.includes('appleCarPlay')?<div><img src= {appleCarPlayLogo}/></div>: ''}


{data.includes('androidAuto')?<div><img src= {androidAutoLogo}/></div>: ''}


{data.includes('amazonAlexa')?<div><img src= {amazonAlexaLogo}/></div>: ''}
{data.includes('blueLink')?<div><img src= {blueLinkLogo}/></div>: ''} 

{data.includes('vivadiBrowser')?<div><img className="h-32" src= {VivadiLogo}/></div>: ''} 

{data.includes('cariad')?<div><img className="h-32" src= {cariadLogo}/></div>: ''} 

{data.includes('Over-the-air')?<div className="w-full"><h3 className="text-left left">Over-the-Air</h3></div>: ''}

</>
                     })}
                     </div>
                    </Text>
                  </Collapse>

                  <Collapse
                    title="Colors"
                    className="collapse-bg"
                    showArrow={true}>

                    <Text>
                    <div className="color-select-main">
                      {selectedModelData?.colors.map((item)=>{
return <>
  <div className="color-panel">
  <span onClick={carColorSelect} style={{backgroundColor: `${item}`}}></span>
  </div>
  </>
                      })}
                      </div>

                    </Text>
                  </Collapse>

                  <Collapse
                    title="Special Features"
                    className="collapse-bg"
                    showArrow={true}
                  >
                    <Text>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </Text>
                  </Collapse>

                  <Collapse
                    title="Warranty"
                    className="collapse-bg"
                    showArrow={true}>
                    <Text>
                      <div className="flex justify-around">
                  <div className="warranty-main">
                  <div className="warran-m third-box">
                      Vehicle 
                    </div>
                    <div className="warran-bottom">
                      <div className="warranty-nos">2</div>Jahre/10,000KM
                    </div>
                    </div>

                    <div className="warranty-main">
                  <div className="warran-m middle-box">
                      Mobility
                    </div>
                    <div className="warran-bottom">
                    <div className="warranty-nos">3</div>Jahre
                    </div>
                    </div>

                    <div className="warranty-main">
                  <div className="warran-m first-box">
                      Battery
                    </div>
                    <div className="warran-bottom">
                    <div className="warranty-nos">8</div>Jahre/160,000KM
                    </div>
                    </div>
                  </div>
                    </Text>
                  </Collapse>

                  <Collapse
                    title="Best Youtube Videos"
                    className="collapse-bg"
                    showArrow={true}>
                    <div className="mt-4">
                    <div className="pt-4">
                    <iframe width="100%" height="280" src={selectedModelData?.youtubeLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    </div>
                  </Collapse>

                </Collapse.Group>
              </Grid>
            </Grid.Container>
            </div>
           
            <div className="flex header-desktop">
            <div className="pl-10 w-[300px]">
              <p className="italic">Startpreis ab</p>
              <p className="text-2xl font-semibold">{`${selectedModelData?.startPrice} €`} </p>
            </div>
            <div className="pl-24 w-[300px]">
              <p className="italic">Leasing Pries ab</p>
              <p className="text-2xl font-semibold">{`${selectedModelData?.leasingPrice} €`}</p>
            </div>
            </div>
            <div>
            <button onClick={() => setBookTestDrive(true)} className="header-desktop w-[94%] mt-10 ml-6 py-3 bg-[#61e001] text-white capitalize rounded-xl text-xl">
              Book a test drive
            </button>
            </div>
          </div>

          <div className="features-brand flex mb-[20px] justify-between">
<div className="w-[97%] text-center mt-[0px]">
<Link target='_blank' to={selectedModelData?.productLink}>
<button className="w-[95%] mt-2 py-3 bg-[#000] text-white capitalize rounded-xl text-xl">
visit <span className="capitalize">{productData[0]?.model}</span> website
            </button>
              </Link>
</div>
<div className="w-[95%] text-center mt-[8px]">
<button onClick={() => setBookTestDrive(true)} className="w-[100%] mt-0 py-3 bg-[#61e001] text-white capitalize rounded-xl text-xl">
              Book a test drive
            </button>
            </div>
</div>

        </div>
      </div>

      <div className="relative bottom-mobile">
        <div className="container mx-auto w-full max-w-none">
          <div className="bottom-main px-5 h-20 left-0 bottom-0 w-full fixed flex justify-between">
            <div className="">
              <img src={logo} className="w-32"></img>
            </div>
          
            
            <div className="flex justify-center w-full">
              <div className="mr-5 border-r pr-5 border-black">
                  <p className="text-xl font-semibold">
                  1 Monat
                  </p>
                  <p className="italic text-sm pt-1">Lieferzeitraum</p>
              </div>
              <div className="mr-5 border-r pr-5 border-black">
               
                  <p className="text-xl font-semibold">
                    {selectedModelData?.batteryCapacity}<small className="text-xs">kwh</small>
                  </p>
                  <p className="italic text-sm pt-1">Battery size</p>
               
              </div>

              <div className="mr-3 border-r pr-5 border-black">
                <p className="text-xl font-semibold">
                {selectedModelData?.rangeWLTP}<small className="text-xs">km</small>
                </p>
                <p className="italic text-sm pt-1">Reichweite (WLTP)</p>
              </div>
              <div className="mr-3 border-r pr-5 border-black">
                <p className="text-xl font-semibold">
                {selectedModelData?.kmh}<small className="text-xs">sec</small>
                </p>
                <p className="italic text-sm pt-1">0–100 km/h</p>
              </div>
              <div className="mr-3 border-r pr-5 border-black">
                <p className="text-xl font-semibold">
                  {selectedModelData?.maxSpeed}<small className="text-xs">km/h</small>
                </p>
                <p className="italic text-sm pt-1">Max. Speed</p>
              </div>

              <div className="mr-3">
                <p className="text-xl font-semibold">
                {selectedModelData?.environmentalBonus==''?'-':`${selectedModelData?.environmentalBonus} €`}
                </p>
                <p className="italic text-sm pt-1">umweltbonus</p>
              </div>
             
            </div>
            <div>
              <Link target='_blank' to={selectedModelData?.productLink}>
              <button className="w-[180px] py-3 bg-black rounded-full text-white uppercase">
                visit <span className="uppercase">{productData[0]?.model}</span> website
              </button>
              </Link>
            </div>
          </div>
          <Transition.Root show={chargingModel} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setChargingModel}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full max-w-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    ></Dialog.Title>
                    <div className="bg-[#f1f1f1] p-4">
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-2xl pl-[6px] text-center font-semibold">
                           Charging Details
                          </h3>
                        </div>
                        <div>
                          <a
                            onClick={() => setChargingModel(false)}
                            className="cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-8 h-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white w-full">
                      <div className="flex w-full sm:flex sm:items-start">
                        <div className="m-0 w-full text-center">
                          <div>
                          <table className="chargingTable">
  <thead>
    <tr>
      <th>Batteriekapazität</th>
      <th>On Board Charger 22kwh</th>
      <th>Ladestation</th>
      <th>Preis / Kwh (€)</th>
      <th>Zeit 0-100%</th>
      <th>Voraussichtliche Kosten</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
    {selectedModelData?.chargingInformation[0].batteryCapacity}
</td>
<td>
    {selectedModelData?.chargingInformation[0].onBoardCharger} kwh
</td>
<td>{selectedModelData?.chargingInformation[0].chargingPoint} kwh</td>
<td> 
Price at €{selectedModelData?.chargingInformation[0].priceKWH} / kwh
</td>
<td>
{selectedModelData?.chargingInformation[0].time} 
</td>
<td> 
€{selectedModelData?.chargingInformation[0].estimatedcost}</td>
    </tr>

    <tr>
      <td>
    {selectedModelData?.chargingInformation[1].batteryCapacity}
</td>
<td>
    {selectedModelData?.chargingInformation[1].onBoardCharger} kwh
</td>
<td>{selectedModelData?.chargingInformation[1].chargingPoint} kwh</td>
<td> 
Price at €{selectedModelData?.chargingInformation[1].priceKWH} / kwh
</td>
<td>
{selectedModelData?.chargingInformation[1].time} 
</td>
<td> 
€{selectedModelData?.chargingInformation[1].estimatedcost}</td>
    </tr>

    <tr>
      <td>
    {selectedModelData?.chargingInformation[2].batteryCapacity}
</td>
<td>
    {selectedModelData?.chargingInformation[2].onBoardCharger} kwh
</td>
<td>{selectedModelData?.chargingInformation[2].chargingPoint} kwh</td>
<td> 
Price at €{selectedModelData?.chargingInformation[2].priceKWH} / kwh
</td>
<td>
{selectedModelData?.chargingInformation[2].time} 
</td>
<td> 
€{selectedModelData?.chargingInformation[2].estimatedcost}</td>
    </tr>
  </tbody>
</table>
                          {/* <ChargingDetails/> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={bookTestDrive} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setChargingModel}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full max-w-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="dialog-mobile relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:w-[60%]">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    ></Dialog.Title>
                    <div className="bg-[#f1f1f1] p-4">
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-2xl pl-[6px] text-center font-semibold capitalize">
                          Book a test drive
                          </h3>
                        </div>
                        <div>
                          <a
                            onClick={() => setBookTestDrive(false)}
                            className="cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-8 h-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white w-full">
                      <div className="flex w-full sm:flex sm:items-start">
                        <div className="m-0 w-full text-center">
                          <div>
                         <BookTestDriveForm/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        </div>
      </div>
    </>
  );
};

export default BrandProduct;
