import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
const ForgotPassword = ()=>{

    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = { email };
        fetch("http://backendnode.elektroautoshowroom.com/api/forgot_password", {
          method: "POST",
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
            "Content-Type": "application/json",
            Cookie: "cross-site-cookie=name",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data.status);
            console.log(data);
          if(data.status === true){
            navigate("/login");  
          }
    
            // handle successful login scenario
          })
          .catch((error) => {
            console.error(error);
            // handle login failure scenario
          });
      };

    return (
        <>
           <Header/>
        <form onSubmit={handleSubmit} className="w-[500px] px-5 mx-auto my-16 mt-12 sm:mt-14">
        <h2 className="text-2xl">Forgot Password</h2>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <div className="mt-2.5">
              <div>
              <input
                type="text"
                name="email"
                id="email"
                value={email} onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                placeholder="Email"
                className="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-black sm:text-sm sm:leading-2 ${}"
              />
              </div>
            </div>
          </div>

        </div>
        <div className="mt-3 text-right">
            <Link to='/login'>
          <span className="hover:underline cursor-pointer">
            Already know your password?
          </span>
          </Link>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-black px-3.5 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-black-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Reset Password
          </button>
        </div>
      </form>
      <Footer/>
        </>
    )
}

export default ForgotPassword;
