import classes from './ChargingDetails.module.css';
const ChargingDetails =()=>{
    return(
        <>
    <table className={classes.chargingTable}>
  <thead>
    <tr>
      <th>Model</th>
      <th>Charging Point</th>
      <th>% of Battery</th>
      <th>Charge Time</th>
      <th>Estimated Cost</th>
    </tr>
  </thead>
  <tbody>
    <tr>
    <td>Audi e-tron GT quattro</td>
      <td>11 kw</td>
      <td>0-100%</td>
      <td>11h45m</td>
      <td>(35 cents/kwh)</td>
    </tr>
    <tr>
    <td>Q4 Sportback e-tron</td>
      <td>22kw</td>
      <td>0-100%</td>
      <td>11h45m</td>
      <td>(35 cents/kwh)</td>
    </tr>
    <tr>
    <td>Audi e-tron GT quattro</td>
      <td>150kw</td>
      <td>0-100%</td>
      <td>11h45m</td>
      <td>(55 cents/kwh)</td>
    </tr>
  
   
  </tbody>
</table>
        </>
    )
}

export default ChargingDetails;