import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import * as classes from './Login.module.css';
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  async function fetchHandler (){
    try {
      const response = await fetch('http://api_fss.avp-applination.com/api/getfavoritebyuserid',{
        method: 'GET',
        headers: {
          "Content-Type":  'application/json',
          "Authorization": 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          "token": `${localStorage.getItem('token')}`
        }
      });
const dataResponse = await response.json();

console.log(dataResponse);

    }
      catch (error){
        console.log(error)
      }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = { email, password };
    fetch("http://backendnode.elektroautoshowroom.com/api/login", {
      method: "POST",
      headers: {
        Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
        "Content-Type": "application/json",
        Cookie: "cross-site-cookie=name",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.status);
        console.log(data);
        const userID = data.data.id
      if(data.status === true){
        fetchHandler();
        localStorage.setItem('isLoggedIn', true)
        localStorage.setItem('userid', userID)
        localStorage.setItem('firstname', data.data.firstname)
        localStorage.setItem('lastname', data.data.lastname)
        localStorage.setItem('token', data.data.token)
        navigate("/");  
      }
      else {
        console.log('nh dik')
      }

        // handle successful login scenario
      })
      .catch((error) => {
        console.error(error);
        // handle login failure scenario
      });
  };




  return (
    <>
     <Header/>
      <form onSubmit={handleSubmit} className="w-[500px] px-5 mx-auto my-16 mt-12 sm:mt-14">
        <h2 className="text-2xl">Login</h2>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <div className="mt-2.5">
              <div>
              <input
                type="text"
                name="email"
                id="email"
                value={email} onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                placeholder="Email"
                className="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-black sm:text-sm sm:leading-2 ${}"
              />
              </div>
            </div>
          </div>

          <div className="sm:col-span-2">
            <div className="mt-0">
              <input
                type="password"
                name="password"
                value={password} onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                id="Password"
                className="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-black sm:text-sm sm:leading-2"
              />
            </div>
          </div>
        </div>
        <div className="mt-3 text-left">
          <Link to ='/register'>
          <span className="hover:underline cursor-pointer">
            Not a user? Register with us
          </span>
          </Link>
        </div>
        <div className="mt-3 text-right">
          <Link to ='/forgotpassword'>
          <span className="hover:underline cursor-pointer">
            Forgot your password?
          </span>
          </Link>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-black px-3.5 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-black-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Login
          </button>
        </div>
      </form>
      <Footer/>
    </>
  );
};

export default Login;
