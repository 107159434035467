import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import './Home.css';
import {useState, useEffect} from 'react';
import { Blurhash } from "react-blurhash";

const Home = ({src, brandLogo}) => {

  useEffect(()=>{
const img = new Image()
img.onload = ()=>{
  setImageLoaded(true)
}
img.src= src
  },[src])
 

const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
        <div className="flex flex-wrap w-[100%] mb-10 mobile-custom">
        <div className="md:w-5/6 sm:w-100">
        
        {!imageLoaded && (
                <Blurhash
                hash ='LrJk7Vt7-nt6~WofWUj[9vjZIpWB'
                width = '100%'
                height = '600px'
                resolutionX = {32}
                resolutionY = {32}
                punch={1}/>
              )}

{imageLoaded && (
          <img
            className="object-cover md:h-[600px] w-full sm:h-auto"
            src={src}
          />
            )}
        </div>
        <div className="md:w-1/6 sm:w-[100%] bg-[#eee] max-[600px] w-full">
         <div className="flex md:flex-col md:justify-center sm:justify-between items-center h-[100%] brand-section home-bottom">
            <div>
                <img
                src={brandLogo}
                className="max-w-[40%] sm:w-[100px] md:w-[100%] md:max-w-full"
                alt="Audi"
              />
            </div>
    
            <div className="md:mt-8 sm:mt-0">
                <button className="visit-btn px-9 py-2 bg-black text-lg text-white rounded-full hover:bg-[#000000d4]">
                  Visit Showroom
                </button>
            
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;
