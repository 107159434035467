import {useRouteError} from 'react-router-dom'
const Error = ()=>{
    const errrData = useRouteError();
    console.log(errrData);
    return (
        <>
        <h1>{errrData.status}</h1>
        <h2>{errrData.statusText}</h2>
        <h2>{errrData.data}</h2>
        </>
    )
}
export default Error;